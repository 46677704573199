import React, { useState } from 'react';
import {
  Paper, makeStyles, TextField, Grid,
} from '@material-ui/core';
import { COLORS } from '../utils/ApplicationConstants';

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    flexGrow: 1,
  },
  paperRoot: {
    padding: theme.spacing(4),
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    width: '100%',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  formButton: {
    border: 'none',
    backgroundColor: 'transparent',
    color: COLORS.CONGRESS_BLUE,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontFamily: 'Roboto-Medium',
    cursor: 'pointer',
    margin: '32px 0',
  },
  errorHeader: {
    fontSize: '18px',
    color: COLORS.NERO,
    fontWeight: '500',
    marginTop: '57px',
    marginBottom: '0',
  },
}));

const UserInformation = ({ userInformation, saveUserInformationHandler }) => {
  const classes = useStyles();
  const [formDisabled, setFormDisabled] = useState(true);
  const [form, setUserInformationForm] = useState(userInformation);
  function renderDisabledFormButtons() {
    return (
      <button
        type="button"
        className={classes.formButton}
        onClick={() => setFormDisabled(!formDisabled)}
      >
        Edit
      </button>
    );
  }

  function submitHandler() {
    setFormDisabled(true);
    saveUserInformationHandler(form);
  }

  function renderEditedFormButtons() {
    return (
      <>
        <button
          type="button"
          className={classes.formButton}
          onClick={() => setFormDisabled(!formDisabled)}
        >
          cancel
        </button>
        <button
          type="button"
          className={classes.formButton}
          onClick={() => submitHandler()}
        >
          Save Changes
        </button>
      </>
    );
  }

  function renderButtonRow() {
    if (formDisabled) {
      return renderDisabledFormButtons();
    }
    return renderEditedFormButtons();
  }

  function handleInputChangeDetails(event) {
    const userInformationCopy = { ...form };
    userInformationCopy.details[event.target.name] = event.target.value;
    setUserInformationForm(userInformationCopy);
  }
  function handleInputChangePhone(event) {
    const userInformationCopy = { ...form };
    userInformationCopy.phone[event.target.name] = event.target.value;
    setUserInformationForm(userInformationCopy);
  }
  function handleInputChangeAddress(event) {
    const userInformationCopy = { ...form };
    userInformationCopy.address[event.target.name] = event.target.value;
    setUserInformationForm(userInformationCopy);
  }
  if (!form) {
    return (
      <Paper classes={{ root: classes.paperRoot }}>
        <h1 className={classes.errorHeader}>
          Error retrieving user information, please doublecheck kit
        </h1>
      </Paper>
    );
  }
  return (
    <Paper classes={{ root: classes.paperRoot }}>
      <form>
        <div className={classes.gridRoot}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                classes={{ root: classes.textFieldRoot }}
                required
                label="First Name"
                value={form?.details?.firstName}
                name="firstName"
                onChange={handleInputChangeDetails}
                disabled={formDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                classes={{ root: classes.textFieldRoot }}
                required
                name="lastName"
                label="Last Name"
                value={form?.details?.lastName}
                onChange={handleInputChangeDetails}
                disabled={formDisabled}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                classes={{ root: classes.textFieldRoot }}
                required
                label="E-Mail"
                value={form?.details?.email}
                name="email"
                onChange={handleInputChangeDetails}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                classes={{ root: classes.textFieldRoot }}
                required
                name="phoneNumber"
                label="Phone Number"
                value={form?.phone?.phoneNumber}
                onChange={handleInputChangePhone}
                disabled={formDisabled}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                classes={{ root: classes.textFieldRoot }}
                required
                label="Address Line 1"
                name="addressLine1"
                value={form?.address?.addressLine1}
                onChange={handleInputChangeAddress}
                disabled={formDisabled}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                classes={{ root: classes.textFieldRoot }}
                label="Address Line 2"
                name="addressLine2"
                value={form?.address?.addressLine2}
                onChange={handleInputChangeAddress}
                disabled={formDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                classes={{ root: classes.textFieldRoot }}
                label="City"
                name="city"
                disabled={formDisabled}
                value={form?.address?.city}
                onChange={handleInputChangeAddress}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                classes={{ root: classes.textFieldRoot }}
                label="State"
                name="state"
                value={form?.address?.state}
                onChange={handleInputChangeAddress}
                disabled={formDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                classes={{ root: classes.textFieldRoot }}
                label="Zip"
                name="zipCode"
                value={form?.address?.zipCode}
                onChange={handleInputChangeAddress}
                disabled={formDisabled}
              />
            </Grid>
          </Grid>
        </div>
      </form>
      <div className={classes.buttonContainer}>
        {renderButtonRow()}
      </div>
    </Paper>
  );
};

export default UserInformation;
