import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

const PrivateRoute = ({ children, ...rest }) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <Route {...rest}>
    <AuthenticatedTemplate>
      {children}
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <Redirect to={{ pathname: '/' }} />
    </UnauthenticatedTemplate>
  </Route>
);
export default PrivateRoute;
