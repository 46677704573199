import React, { useState } from 'react';
import {
  makeStyles, Modal, Paper, Grid, TextField,
} from '@material-ui/core';
import { useMsal } from '@azure/msal-react';
import checkMark from '../images/checkMark.svg';
import { COLORS } from '../utils/ApplicationConstants';
import { getUserInformation, reshipKit } from '../services/cshApiService';

const useStyles = makeStyles((theme) => ({
  journeyItemRoot: {
    display: 'flex',
    alignItems: 'self-start',
    position: 'relative',
    '&:not(:last-child)': {
      '&::before': {
        content: "''",
        width: '1px',
        position: 'absolute',
        top: '30px',
        left: '11px',
        height: 'calc(100% - 36px)',
        backgroundColor: '#DDDDDD',
      },

    },
  },
  checkMarkImage: {
    marginRight: '12px',
  },
  journeyDescription: {
    paddingBottom: '40px',
    paddingTop: '3px',
    marginTop: '0',
    fontSize: '14px',
    fontWeight: '500',
  },
  journeyContainer: {
    display: 'flex',
    flexFlow: 'column',
  },
  textFieldRoot: {
    width: '100%',
  },
  reShippableSpacing: {
    paddingBottom: '20px',
  },
  modalBackground: {
    backgroundColor: COLORS.WHITE,
    margin: '50px auto',
    width: '45%',
    padding: '50px',
    paddingBottom: '30px',
    borderRadius: '15px',
    [theme.breakpoints.down('sm')]: {
      width: '65%',
    },
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  formButton: {
    border: 'none',
    backgroundColor: 'transparent',
    color: COLORS.CONGRESS_BLUE,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontFamily: 'Roboto-Medium',
    cursor: 'pointer',
    margin: '32px 0',
  },
  secondaryButton: {
    color: COLORS.CONGRESS_BLUE,
    backgroundColor: COLORS.WHITE,
    fontFamily: 'Roboto',
    marginBottom: '20px',
    textTransform: 'uppercase',
    width: 'fit-content',
    padding: '13px 37px',
    border: `2px solid ${COLORS.CONGRESS_BLUE}`,
    borderRadius: '3px',
    letterSpacing: '0.5px',
    cursor: 'pointer',
    transition: '0.2s',
    '&:hover': {
      opacity: '0.9',
    },
  },
}));

const JourneyItem = ({
  journeyDescription, idPayload, kitKey, retriggerLoadOnReship,
}) => {
  const classes = useStyles();
  const { customerJourneyStep, isReshippable } = journeyDescription;
  const [showModal, setShowModal] = useState(false);
  const [userAddress, setUserAddress] = useState({});
  const msalReference = useMsal();
  const [isShipButtonDisabled, setShipButtonDisabledStatus] = useState(false);
  async function reshipKitToggle() {
    const address = await getUserInformation(idPayload, msalReference);
    setUserAddress(address);
    setShowModal(true);
  }

  function renderReshippableButton() {
    if (isReshippable) {
      return (
        <button
          type="button"
          className={classes.secondaryButton}
          onClick={reshipKitToggle}
        >
          Reship Kit
        </button>
      );
    }
    return null;
  }

  async function toggleReship() {
    const reshipPayload = {
      userId: idPayload?.patientId ? idPayload?.patientId : idPayload?.userId,
      kitKeyToCancel: kitKey?.kitKey,
      useSameAddress: true,
    };
    setShipButtonDisabledStatus(true);
    await reshipKit(reshipPayload, msalReference);
    setShowModal(false);
    setShipButtonDisabledStatus(false);
    retriggerLoadOnReship();
  }

  function setJourneyStyle() {
    if (isReshippable) {
      return `${classes.journeyDescription} ${classes.reShippableSpacing}`;
    }
    return classes.journeyDescription;
  }
  return (
    <div className={classes.journeyItemRoot}>
      <img className={classes.checkMarkImage} src={checkMark} alt="Checkmark Icon" />
      <div className={classes.journeyContainer}>
        <p className={setJourneyStyle()}>{customerJourneyStep}</p>
        {renderReshippableButton()}
      </div>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <Paper classes={{ root: classes.modalBackground }}>
          <form>
            <div className={classes.gridRoot}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    classes={{ root: classes.textFieldRoot }}
                    required
                    label="First Name"
                    value={userAddress?.details?.firstName}
                    name="firstName"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    classes={{ root: classes.textFieldRoot }}
                    required
                    name="lastName"
                    label="Last Name"
                    value={userAddress?.details?.lastName}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    classes={{ root: classes.textFieldRoot }}
                    required
                    label="E-Mail"
                    value={userAddress?.details?.email}
                    name="email"
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    classes={{ root: classes.textFieldRoot }}
                    required
                    name="phoneNumber"
                    label="Phone Number"
                    value={userAddress?.phone?.phoneNumber}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    classes={{ root: classes.textFieldRoot }}
                    required
                    label="Address Line 1"
                    name="addressLine1"
                    value={userAddress?.address?.addressLine1}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    classes={{ root: classes.textFieldRoot }}
                    label="Address Line 2"
                    name="addressLine2"
                    value={userAddress?.address?.addressLine2}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    classes={{ root: classes.textFieldRoot }}
                    label="City"
                    name="city"
                    value={userAddress?.address?.city}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    classes={{ root: classes.textFieldRoot }}
                    label="State"
                    name="state"
                    value={userAddress?.address?.state}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    classes={{ root: classes.textFieldRoot }}
                    label="Zip"
                    name="zipCode"
                    value={userAddress?.address?.zipCode}
                    disabled
                  />
                </Grid>
              </Grid>
            </div>
          </form>
          <div className={classes.buttonContainer}>
            <button
              type="button"
              className={classes.formButton}
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              disabled={isShipButtonDisabled}
              className={classes.formButton}
              onClick={toggleReship}
            >
              Reship
            </button>
          </div>
        </Paper>
      </Modal>
    </div>
  );
};

export default JourneyItem;
