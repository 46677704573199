import React from 'react';
import { AppBar, makeStyles } from '@material-ui/core';
import { useMsal, AuthenticatedTemplate } from '@azure/msal-react';
import { COLORS } from '../utils/ApplicationConstants';
import Logo from '../images/logo.svg';
import { loginRequest } from '../utils/authConfig';

const useStyles = makeStyles({
  appBarRoot: {
    height: '64px',
    backgroundColor: COLORS.WHITE,
    boxShadow: '0px 0px 4px #00000033',
  },
  logo: {
    height: '24px',
    marginRight: '20px',
  },
  logoContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  appTitle: {
    color: COLORS.MONSOON,
    textTransform: 'uppercase',
    letterSpacing: '2.1px',
    lineHeight: '24px',
    fontSize: '14px',
    fontFamily: 'Roboto Condensed',
  },
  logoSeparator: {
    width: '1px',
    height: '24px',
    backgroundColor: COLORS.ALTO,
    marginRight: '20px',
  },
  appBarContentContainer: {
    maxWidth: '1200px',
    margin: '0 auto',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logoutButton: {
    border: 'none',
    backgroundColor: 'transparent',
    color: COLORS.CONGRESS_BLUE,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontFamily: 'Roboto-Medium',
    cursor: 'pointer',
  },
});

const Navigation = () => {
  const classes = useStyles();
  const { instance } = useMsal();
  const handleLogout = () => {
    instance.logoutRedirect(loginRequest);
  };

  return (
    <>
      <AppBar classes={{ root: classes.appBarRoot }}>
        <div className={classes.appBarContentContainer}>
          <div className={classes.logoContainer}>
            <img className={classes.logo} alt="Grifols Logo" src={Logo} />
            <div className={classes.logoSeparator} />
            <span className={classes.appTitle}>Customer Support Hub</span>
          </div>
          <AuthenticatedTemplate>
            <div className={classes.logoutButton}>
              <button className={classes.logoutButton} type="button" onClick={handleLogout}>Logout</button>
            </div>
          </AuthenticatedTemplate>
        </div>
      </AppBar>
    </>
  );
};

export default Navigation;
