import React, { useEffect, useState } from 'react';
import {
  Grid, makeStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import UserInformation from '../components/UserInformation';
import JourneyPanel from '../components/JourneyPanel';
import { getUserAndJourneyInformation, setUserInformation } from '../services/cshApiService';
import Loader from '../components/Loader';
import { COLORS } from '../utils/ApplicationConstants';

const useStyles = makeStyles((theme) => ({
  fullName: {
    fontSize: '18px',
    color: COLORS.NERO,
    fontWeight: '500',
    marginTop: '57px',
    marginBottom: '0',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '27px',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column',
      alignItems: 'center',
    },
  },
  primaryButton: {
    backgroundColor: COLORS.ENDEAVOUR,
    color: COLORS.WHITE,
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    paddingTop: '9px',
    paddingBottom: '9px',
    height: '44px',
    border: 'none',
    borderRadius: '3px',
    width: '153px',
    letterSpacing: '0.5px',
    cursor: 'pointer',
    transition: '0.2s',
    '&:hover': {
      opacity: '0.9',
    },
  },
}));
const JourneyPage = () => {
  const [journeyPageDataSet, setJourneyPageDataSet] = useState({});
  const [isLoading, setLoader] = useState(true);
  const [retriggerLoad, setRetriggerLoad] = useState(false);
  const history = useHistory();
  const { matchingPatientIds, matchingKitKeys, matchingUserIds } = history.location.state;
  const classes = useStyles();
  const msalReference = useMsal();
  function setAsFirstIndex(idArray) {
    if (idArray[0]) {
      return idArray[0];
    }
    return null;
  }
  const idPayload = {
    patientId: setAsFirstIndex(matchingPatientIds),
    userId: setAsFirstIndex(matchingUserIds),
  };
  const kitKeyPayload = {
    kitKey: setAsFirstIndex(matchingKitKeys),
  };

  const retriggerLoadOnReship = () => {
    setRetriggerLoad(!retriggerLoad);
  };

  async function journeyPageSetup() {
    let journeyInformationResponse;
    try {
      // eslint-disable-next-line max-len
      journeyInformationResponse = await getUserAndJourneyInformation(idPayload, kitKeyPayload, msalReference);
    } catch (error) {
      console.error(error);
    }
    setJourneyPageDataSet(journeyInformationResponse);
  }
  async function saveUserInformationHandler(userInformation) {
    try {
      await setUserInformation(userInformation, msalReference);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(async () => {
    await setLoader(true);
    await journeyPageSetup();
    await setLoader(false);
  }, [retriggerLoad]);
  if (isLoading) {
    return <Loader />;
  }
  const { userInformation, userJourney } = journeyPageDataSet;
  const { fullName } = userInformation?.address || '';
  return (
    <>
      <div className={classes.row}>
        <h1 className={classes.fullName}>{fullName}</h1>
        <button
          onClick={() => history.push('/verify')}
          className={classes.primaryButton}
          type="button"
        >
          Start Over
        </button>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <div>
            <UserInformation
              userInformation={userInformation}
              saveUserInformationHandler={saveUserInformationHandler}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div>
            <JourneyPanel
              idPayload={idPayload}
              kitKey={kitKeyPayload}
              userJourney={userJourney}
              retriggerLoadOnReship={retriggerLoadOnReship}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default JourneyPage;
