import React from 'react';
import { MsalProvider } from '@azure/msal-react';
import Layout from './layouts/Layout';

function App({ pca }) {
  return (
    <>
      <MsalProvider instance={pca}>
        <Layout />
      </MsalProvider>
    </>
  );
}

export default App;
