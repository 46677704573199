import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Success from '../pages/Success';
import JourneyPage from '../pages/JourneyPage';
import PrivateRoute from './PrivateRoute';
import VerifyWizard from '../pages/VerifyWizard';

const AppRouter = () => (
  <Switch>
    <Route exact path="/">
      <Home />
    </Route>
    <PrivateRoute exact path="/success">
      <Success />
    </PrivateRoute>
    <PrivateRoute exact path="/verify">
      <VerifyWizard />
    </PrivateRoute>
    <PrivateRoute exact path="/journey">
      <JourneyPage />
    </PrivateRoute>
  </Switch>
);

export default AppRouter;
