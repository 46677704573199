import React from 'react';

import { CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  loaderRoot: {
    height: 'calc(100vh - 64px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
const Loader = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.loaderRoot}>
        <CircularProgress />
      </div>
    </>
  );
};

export default Loader;
