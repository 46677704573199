import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useHistory } from 'react-router-dom';
import Logo from '../images/logo.svg';
import { COLORS } from '../utils/ApplicationConstants';
import { loginRequest } from '../utils/authConfig';

const useStyles = makeStyles({
  homePageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexFlow: 'column',
  },
  homePageTitle: {
    color: COLORS.MONSOON,
    textTransform: 'uppercase',
    letterSpacing: '2.1px',
    lineHeight: '24px',
    fontSize: '24px',
    fontWeight: '100',
    fontFamily: 'Roboto Condensed',
  },
  invalidRequest: {
    color: COLORS.RED,
    textTransform: 'uppercase',
    letterSpacing: '2.1px',
    fontSize: '20px',
  },
  primaryButton: {
    backgroundColor: COLORS.ENDEAVOUR,
    color: COLORS.WHITE,
    textTransform: 'uppercase',
    fontFamily: 'Roboto',
    paddingTop: '9px',
    paddingBottom: '9px',
    border: 'none',
    borderRadius: '3px',
    maxWidth: '330px',
    width: '100%',
    letterSpacing: '0.5px',
    cursor: 'pointer',
    transition: '0.2s',
    '&:hover': {
      opacity: '0.9',
    },
  },
});
const Home = () => {
  const classes = useStyles();
  const { instance } = useMsal();
  const history = useHistory();
  const wasInvalidRequest = window.location.hash === '#invalidRequest';
  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };
  return (
    <div className={classes.homePageContainer}>
      <img alt="Grifols Logo" src={Logo} />
      <h1 className={classes.homePageTitle}>Customer Support Hub</h1>
      {wasInvalidRequest
      ? <p className={classes.invalidRequest}>Invalid Account: Please Retry</p> : null}
      <UnauthenticatedTemplate>
        <button className={classes.primaryButton} type="button" onClick={handleLogin}>Sign In</button>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <button className={classes.primaryButton} type="button" onClick={() => history.push('/verify')}>Verify User</button>
      </AuthenticatedTemplate>
    </div>
  );
};

export default Home;
