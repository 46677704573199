import axios from 'axios';

async function setSystemInstance(msalInstance) {
  const { accounts, instance } = msalInstance;
  const accessTokenRequest = {
    scopes: [''],
    account: accounts[0],
  };
  const tokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
  return axios.create({
    baseURL: process.env.REACT_APP_BASE_API,
    headers: {
      Authorization: `Bearer ${tokenResponse?.idToken}`,
    },
  });
}

async function setFulfillmentInstance(msalInstance) {
  const { accounts, instance } = msalInstance;
  const accessTokenRequest = {
    scopes: [''],
    account: accounts[0],
  };
  const tokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
  return axios.create({
    baseURL: process.env.REACT_APP_FULFILLMENT_API,
    headers: {
      Authorization: `Bearer ${tokenResponse?.idToken}`,
    },
  });
}

// eslint-disable-next-line import/prefer-default-export
export { setSystemInstance, setFulfillmentInstance };
