const COLORS = {
  ALABASTER: '#FAFAFA',
  WHITE: '#FFFFFF',
  MONSOON: '#777777',
  ALTO: '#DADADA',
  ENDEAVOUR: '#264A8A',
  CONGRESS_BLUE: '#004E87',
  NERO: '#272727',
  RED: '#ff0000',
};
const b2cPolicies = {
  names: {
    signUpSignIn: process.env.REACT_APP_B2C_POLICY,
  },
  authorities: {
    signUpSignIn: {
      authority: process.env.REACT_APP_AUTHORITY,
    },
  },
};

const MSAL_CONFIG = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorities.signUpSignIn.authority],
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_URI,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
  },
};

export { COLORS, MSAL_CONFIG };
