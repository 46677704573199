import React, { useEffect, useState } from 'react';
import {
  Grid, makeStyles, Paper, TextField, CircularProgress,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { COLORS } from '../utils/ApplicationConstants';
import { verifyCaller } from '../services/cshApiService';

const useStyles = makeStyles((theme) => ({
  wizardPageContainer: {
    height: 'calc(100vh - 64px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wizardPaperRoot: {
    padding: theme.spacing(4),
    color: theme.palette.text.primary,
    minWidth: '360px',
    maxWidth: '532px',
    width: 'fit-content',
  },
  stepDescription: {
    color: COLORS.MONSOON,
    fontSize: '16px',

  },
  formButton: {
    border: 'none',
    backgroundColor: 'transparent',
    color: COLORS.CONGRESS_BLUE,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontFamily: 'Roboto-Medium',
    cursor: 'pointer',
    margin: '32px 0',
    '&:first-child': {
      marginRight: '32px',
    },
  },
  textFieldRoot: {
    width: '100%',
  },
  gridRoot: {
    flexGrow: 1,
  },

  panelHeader: {
    fontSize: '20px',
    color: COLORS.NERO,
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  primaryButton: {
    backgroundColor: COLORS.ENDEAVOUR,
    color: COLORS.WHITE,
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    paddingTop: '9px',
    paddingBottom: '9px',
    height: '44px',
    border: 'none',
    borderRadius: '3px',
    width: '100px',
    letterSpacing: '0.5px',
    cursor: 'pointer',
    transition: '0.2s',
    '&:hover': {
      opacity: '0.9',
    },
  },
  errorMessage: {
    color: 'red',
  },
}));
const defaultVerificationResource = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  barCode: '',
};

const VerifyWizard = () => {
  const classes = useStyles();
  const [wizardStep, setWizardStep] = useState(1);
  const [buttonLoading, setButtonLoading] = useState();
  const [verificationResource, setVerificationResource] = useState(defaultVerificationResource);
  const [hasErrorSubmitting, setSubmissionError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  const msalReferences = useMsal();
  useEffect(() => {
    if (hasErrorSubmitting) {
      setSubmissionError(false);
    }
  }, [wizardStep]);
  function setStep(step) {
    setWizardStep(step);
    setButtonLoading(false);
  }
  function formatResource() {
    const newCustomerResource = { ...verificationResource };
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(newCustomerResource)) {
      if (!value) {
        delete newCustomerResource[key];
      }
    }
    return newCustomerResource;
  }

  async function submitForm() {
    setButtonLoading(true);
    const response = await verifyCaller(formatResource(), msalReferences);

    if (!response?.isSuccess) {
      setSubmissionError(true);
      setButtonLoading(false);
      setErrorMessage(response.response.data);
    }
    if (response?.isSuccess) {
      const { matchingPatientIds, matchingUserIds, matchingKitKeys } = response;
      setButtonLoading(false);
      history.push({
        pathname: '/journey',
        state: {
          matchingPatientIds,
          matchingUserIds,
          matchingKitKeys,
        },
      });
    }
    return null;
  }

  function renderStepControls(cancelStep, forwardStep) {
    return (
      <div className={classes.buttonRow}>
        <button
          type="button"
          className={classes.formButton}
          onClick={() => setStep(cancelStep)}
        >
          No
        </button>
        <button
          type="button"
          className={classes.formButton}
          onClick={() => setStep(forwardStep)}
        >
          Yes
        </button>
      </div>
    );
  }
  function renderSubmitControls(cancelStep) {
    return (
      <div className={classes.buttonRow}>
        <button
          type="button"
          className={classes.formButton}
          onClick={() => setStep(cancelStep)}
        >
          Go Back
        </button>
        { !buttonLoading && (
        <button
          type="button"
          className={classes.primaryButton}
          onClick={() => submitForm()}
        >
          Verify
        </button>
        )}
        {buttonLoading && <CircularProgress /> }
      </div>
    );
  }
  function renderStep1() {
    return (
      <div>
        <p className={classes.stepDescription}>
          Did the customer order an AlphaID™ At Home  Saliva Collection Kit?
        </p>
        {renderStepControls(3, 2)}
      </div>
    );
  }
  function renderStep2() {
    return (
      <div>
        <p className={classes.stepDescription}>
          Does the customer know their AlphaID™ At Home  Saliva Collection Kit unique barcode?
        </p>
        {renderStepControls(3, 4)}
      </div>
    );
  }

  function handleInputChange(event) {
    const newResource = { ...verificationResource };
    newResource[event.target.name] = event.target.value;
    setVerificationResource(newResource);
  }
  function renderStep3() {
    return (
      <div>
        <form>
          <div className={classes.gridRoot}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  classes={{ root: classes.textFieldRoot }}
                  required
                  name="firstName"
                  label="First Name"
                  value={verificationResource.firstName}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  classes={{ root: classes.textFieldRoot }}
                  required
                  name="lastName"
                  label="Last Name"
                  value={verificationResource.lastName}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  classes={{ root: classes.textFieldRoot }}
                  required
                  name="emailAddress"
                  label="E-Mail"
                  value={verificationResource.emailAddress}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </div>
        </form>
        {renderSubmitControls(2)}
      </div>
    );
  }
  function renderStep4() {
    return (
      <div>
        <form>
          <div className={classes.gridRoot}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  classes={{ root: classes.textFieldRoot }}
                  name="firstName"
                  label="First Name"
                  value={verificationResource.firstName}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  classes={{ root: classes.textFieldRoot }}
                  name="lastName"
                  label="Last Name"
                  value={verificationResource.lastName}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  classes={{ root: classes.textFieldRoot }}
                  required
                  name="barCode"
                  label="Unique Barcode"
                  value={verificationResource.barCode}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </div>
        </form>
        {renderSubmitControls(2)}
      </div>
    );
  }
  function renderWizardSteps() {
    // eslint-disable-next-line default-case
    switch (wizardStep) {
      case 1:
        return renderStep1();
      case 2:
        return renderStep2();
      case 3:
        return renderStep3();
      case 4:
        return renderStep4();
    }
    return null;
  }
  function renderErrorMessage() {
    if (hasErrorSubmitting) {
      return (
        <p className={classes.errorMessage}>
          {errorMessage}
        </p>
      );
    }
    return null;
  }
  return (
    <div className={classes.wizardPageContainer}>
      <Paper classes={{ root: classes.wizardPaperRoot }}>
        <h1 className={classes.panelHeader}>Verify Customer&apos;s Identity</h1>
        {renderWizardSteps()}
        {renderErrorMessage()}
      </Paper>
    </div>
  );
};

export default VerifyWizard;
