import React, { useEffect, useState } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

const useStyles = makeStyles(() => ({
  successPageRoot: {
    height: 'calc(100vh - 64px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
const Success = () => {
  const classes = useStyles();
  const [loaded, setLoadedState] = useState(false);
  const { accounts, instance } = useMsal();
  const isAuthorized = accounts[0]?.idTokenClaims?.extension_CSHAccess;
  useEffect(() => {
    if (!isAuthorized) {
      return null;
    }
    setLoadedState(true);
    return null;
  }, []);
  if (!isAuthorized) {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/#invalidRequest',
    });
  }
  if (loaded) {
    return <Redirect to="/verify" />;
  }
  return (
    <>
      <div className={classes.successPageRoot}>
        <CircularProgress />
      </div>
    </>
  );
};

export default Success;
