import queryString from 'querystring';
import { setSystemInstance, setFulfillmentInstance } from '../utils/AxiosConfig';

async function verifyCaller(callerInformation, msalInstance) {
  let response;
  const axiosInstance = await setSystemInstance(msalInstance);
  const payloadParams = queryString.stringify(callerInformation);
  try {
    response = await axiosInstance.get(`CshVerifyCaller/?${payloadParams}`);
  } catch (error) {
    return error;
  }
  return response.data;
}
async function getUserInformation(idPayload, msalInstance) {
  const payloadObjectCopy = { ...idPayload };
  let response;
  if (!payloadObjectCopy.patientId) {
    delete payloadObjectCopy.patientId;
  }
  const axiosInstance = await setSystemInstance(msalInstance);
  const payloadQueryParams = queryString.stringify(payloadObjectCopy);
  try {
    response = await axiosInstance.get(`CshGetCustomer/?${payloadQueryParams}`);
  } catch (error) {
    throw new Error(error);
  }
  return response.data;
}
async function setUserInformation(userInformation, msalInstance) {
  let response;
  const axiosInstance = await setSystemInstance(msalInstance);
  try {
    response = axiosInstance.put('CshEditCustomer', userInformation);
  } catch (error) {
    throw new Error(error);
  }
  return response.data;
}
async function getUserAndJourneyInformation(idPayload, kitKey, msalInstance) {
  const payloadObjectCopy = { ...idPayload };
  if (!payloadObjectCopy.patientId) {
    delete payloadObjectCopy.patientId;
  }
  const axiosInstance = await setSystemInstance(msalInstance);
  const idPayloadQueryParams = queryString.stringify(payloadObjectCopy);
  const [userInformation, userJourney] = await Promise.allSettled([
    axiosInstance.get(`CshGetCustomer/?${idPayloadQueryParams}`),
    axiosInstance.get(`CshGetCustomerJourney/?${idPayloadQueryParams}`),
  ]);
  return {
    userInformation: userInformation?.value?.data,
    userJourney: userJourney?.value?.data,
  };
}

async function reshipKit(reshipPayload, msalInstance) {
  let response;
  const axiosInstance = await setFulfillmentInstance(msalInstance);
  try {
    response = await axiosInstance.post('ReshipKit', reshipPayload);
  } catch (error) {
    throw new Error(error);
  }
  return response.data;
}

export {
  verifyCaller,
  getUserInformation,
  getUserAndJourneyInformation,
  setUserInformation,
  reshipKit,
};
