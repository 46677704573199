import React from 'react';
import { makeStyles } from '@material-ui/core';
import AppRouter from '../router/AppRouter';
import Navigation from '../components/Navigation';

const useStyles = makeStyles({
  contentContainer: {
    height: 'calc(100vh - 64px)',
    paddingTop: '64px',
  },
  layoutContainer: {
    maxWidth: '1200px',
    margin: '0 auto',
  },
});

const Layout = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.layoutContainer}>
        <Navigation />
        <div className={classes.contentContainer}>
          <AppRouter />
        </div>
      </div>
    </>
  );
};

export default Layout;
